import React from "react";

const FloatingMapCard = ({ text }) => {
  const defaultImgUrl = "https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk";
  
  let cardTextElement = null;
  let fresCommentsElement = null;
  let callinsElement = null;

  if (text) {
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "#1f3652",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p style={{ fontSize: "20px",  }}>{text.CallType}</p>
        <p style={{ fontSize: "20px", color: "yellow" }}>{text.DispatchCode}</p>
        <br />
        <p style={{ fontSize: "20px" }}>{text.Address}</p>
        <p style={{ fontSize: "20px" }}>{text.CrossStreet}</p>
        <br />
        <p style={{ fontSize: "18px" }}>**{text.FRESComments}**</p>
        <br />
        <p style={{ fontSize: "18px" }}>{text.EventComments}</p>
      </div>
    );

    
    if (text.Callins && text.Callins.length > 0) {
      callinsElement = (
        <div
          style={{
            position: "absolute",
            top: "2%",
            right: "230px",
            zIndex: 1000,
            background: "green",
            borderRadius: "8px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            maxWidth: "325px",
            overflowY: "auto",
            maxHeight: "400px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "8px 8px 0 0",
              marginBottom: "10px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Call-ins:<br />
          </div>
          <div
            style={{
              color: "white",
              backgroundColor: "#1f3652",
              padding: "15px",
              borderRadius: "0 0 8px 8px",
              textAlign: "left",
            }}
          >
            {text.Callins.map((callin, index) => (
              <React.Fragment key={index}>
                <div style={{ display: "flex", alignItems: "center"}}>
                  <img
                    src={callin.userImg || defaultImgUrl}
                    alt="User profile"
                    style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px" }}
                  />
                  <div>
                    <p style={{ fontSize: "15px" }}>
                     {callin.name}
                    </p>
                    <p style={{ fontSize: "15px" }}>
                      Response: {callin.response}
                    </p>
                    <p style={{ fontSize: "15px" }}>
                      Called In: {new Date(callin.timestamp.seconds * 1000).toLocaleTimeString()}
                    </p>
                    <p style={{ fontSize: "15px"}}>
                      Qualifications:{(callin.qualifications || []).join(", ")}
                    </p>
                  </div>
                </div>
                {index < text.Callins.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
  } else {
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "#111921",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p>Stand By...Loading Call Data...</p>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "2%",
          left: "10px",
          zIndex: 1000,
          background: "red",
          borderRadius: "8px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
          maxWidth: "425px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "10px",
            borderRadius: "8px 8px 0 0",
            marginBottom: "10px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Alarm Details:<br />
        </div>
        {cardTextElement}
      </div>
      {fresCommentsElement}
      {callinsElement}
    </div>
  );
};

export default FloatingMapCard;
