import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.scss";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import AccountCircleIcon from "@mui/icons-material/Person";
import "./Dashboarddark.scss";

const Dashboard = ({ selectedTab }) => {
  const column1Ref = useRef(null);
  const column2Ref = useRef(null);
  const [column1Data, setColumn1Data] = useState([]);
  const [column2Data, setColumn2Data] = useState([]);
  const [onNowRight, setOnNowRight] = useState([]);
  const [onNowLeft, setOnNowLeft] = useState([]);
  const [onLaterRight, setOnLaterRight] = useState([]);
  const [onLaterLeft, setOnLaterLeft] = useState([]);
  const [onNow, setOnNow] = useState([]);
  const [onLater, setOnLater] = useState([]);
  const [showW2W, setShowW2W] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(1);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [currentView, setCurrentView] = useState("memos");
  const [units, setUnits] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser) {
        signOut(getAuth());
        window.localStorage.removeItem("user");
      }
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      const fetchColumn1Data = async (user) => {
        const column1CollectionRef = collection(db, "users");
        const usersQuery = query(
          column1CollectionRef,
          where("agency", "==", user.agency)
        );
        const unsubscribeUsers = onSnapshot(usersQuery, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          const users = [];
          data.forEach((ele) => {
            if (ele.OndutyEMS || ele.OndutyFire) {
              users.push(ele);
            }
          });
          setColumn1Data(users);
        });
      };

      const fetchUserData = async (userId) => {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        const userName = userDoc.data().name;
        const userImg = userDoc.data().userImg;
        return { userName, userImg };
      };

      const fetchColumn2Data = async (user) => {
        const currentTime = new Date();
        const oneHourAgo = new Date(currentTime);
        oneHourAgo.setHours(currentTime.getHours() - 1);
        const alarmsCollection = collection(db, user.agency);
        const alarmsQuery = query(alarmsCollection);
        const unsubscribeAlarms = onSnapshot(
          alarmsQuery,
          async (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => doc.data());
            const postsWithSeconds = data.filter(
              (post) => post.postTime && post.postTime.seconds
            );

            postsWithSeconds.sort((a, b) => {
              return b.postTime.seconds - a.postTime.seconds;
            });

            const postsWithAllowedPrivacy = postsWithSeconds.filter((post) => {
              const privacy = post.privacy;
              return !privacy || privacy === "All" || privacy === "Stationboard";
            });

            const postsWithUserData = await Promise.all(
              postsWithAllowedPrivacy.map(async (post) => {
                const userData = await fetchUserData(post.userId);
                return { ...post, ...userData };
              })
            );

            setColumn2Data(postsWithUserData);
            setLastUpdated(new Date());
          }
        );

        return () => {
          unsubscribeAlarms();
        };
      };

      const deptCollection = await getDocs(
        collection(db, userDoc.data()["agency"] + " Station Board")
      );
      deptCollection.forEach((dept) => {
        if (dept.data()["when2work"]) {
          setShowW2W(true);
          getShifts(dept.data()["apiKey"], dept.data()["endPoint"]);
          setInterval(() => {
            getShifts(dept.data()["apiKey"], dept.data()["endPoint"]);
          }, 5 * 60 * 1000);
        } else {
          setShowW2W(false);
        }
      });

      const fetchUnits = async () => {
        const unitsCollection = collection(db, `Rigchecks/VgTv80ik2BtavT0A5owc/${userDoc.data().agency} Rigchecks`);
        const unitsSnapshot = await getDocs(unitsCollection);
        const unitsData = unitsSnapshot.docs.map(doc => doc.data());
        setUnits(unitsData);
      };

      fetchColumn1Data(userDoc.data());
      fetchColumn2Data(userDoc.data());
      fetchUnits();
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentView((prevView) => (prevView === "memos" ? "unitStatus" : "memos"));
    }, 30000); // Switch views every 10 seconds
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const scrollColumns = () => {
      if (column1Ref.current) {
        const column1 = column1Ref.current;
        const canScrollColumn1 = column1.scrollHeight > column1.clientHeight;
        if (canScrollColumn1) {
          column1.scrollTop += scrollDirection * 30;
        }
        if (
          column1.scrollTop === 0 ||
          column1.scrollTop + column1.clientHeight >= column1.scrollHeight
        ) {
          setScrollDirection(scrollDirection === 1 ? -1 : 1);
        }
      }
      if (column2Ref.current) {
        const column2 = column2Ref.current;
        const canScrollColumn2 = column2.scrollHeight > column2.clientHeight;
        if (canScrollColumn2) {
          column2.scrollTop += scrollDirection * 30;
        }
        if (
          column2.scrollTop === 0 ||
          column2.scrollTop + column2.clientHeight >= column2.scrollHeight
        ) {
          setScrollDirection(scrollDirection === 1 ? -1 : 1);
        }
      }
    };
    const intervalId = setInterval(scrollColumns, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [scrollDirection]);

  useEffect(() => {
    const checkDarkMode = () => {
      const currentHour = new Date().getHours();
      setIsDarkMode(currentHour < 8 || currentHour >= 17);
    };
    checkDarkMode();
    const intervalId = setInterval(checkDarkMode, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const getShifts = async (api, endPoint) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const yearTomorrow = tomorrow.getFullYear();
    const monthTomorrow = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const dayTomorrow = String(tomorrow.getDate()).padStart(2, "0");

    const getDateTomorrow = `${monthTomorrow}/${dayTomorrow}/${yearTomorrow}`;
    console.log("Fetching data from When2Work API for tomorrow:", getDateTomorrow);

    const getDate = `${month}/${day}/${year}`;
    console.log("Fetching data from When2Work API for date:", getDate);
    let resp = await fetch(
      endPoint + getDate + "&end_date=" + getDateTomorrow + "&key=" + api
    );
    console.log(resp);
    let data = await resp.json();
    console.log("Data from When2Work API:", data);
    splitEventsByTime(data.AssignedShiftList);
    setLastUpdated(new Date());
  };

  const splitEventsByTime = (events) => {
    const currentTime = new Date();
    const tomorrow = new Date(currentTime);
    tomorrow.setDate(currentTime.getDate() + 1);

    const onToday = [];
    const onTomorrow = [];

    events.forEach((event) => {
      const startDateParts = event.START_DATE.split("/");
      const endDateParts = event.END_DATE.split("/");
      const startTimeParts = event.START_TIME.match(/(\d+)(\w+)/);

      if (
        startDateParts.length === 3 &&
        endDateParts.length === 3 &&
        startTimeParts
      ) {
        const startMonth = parseInt(startDateParts[0]) - 1;
        const startDay = parseInt(startDateParts[1]);
        const startYear = parseInt(startDateParts[2]);

        const startHour = parseInt(startTimeParts[1]);
        const startAmPm = startTimeParts[2];

        const startDateTime = new Date(
          startYear,
          startMonth,
          startDay,
          startAmPm === "pm" ? startHour + 12 : startHour,
          0
        );

        if (
          startDateTime.getDate() === currentTime.getDate() &&
          startDateTime.getMonth() === currentTime.getMonth() &&
          startDateTime.getFullYear() === currentTime.getFullYear()
        ) {
          onToday.push(event);
        } else if (
          startDateTime.getDate() === tomorrow.getDate() &&
          startDateTime.getMonth() === tomorrow.getMonth() &&
          startDateTime.getFullYear() === tomorrow.getFullYear()
        ) {
          onTomorrow.push(event);
        }
      }
    });

    const onNowLeft = [];
    const onNowRight = [];
    const onLaterLeft = [];
    const onLaterRight = [];

    onToday.forEach((event, index) => {
      if (index % 2 === 0) {
        onNowLeft.push(event);
      } else {
        onNowRight.push(event);
      }
    });

    onTomorrow.forEach((event, index) => {
      if (index % 2 === 0) {
        onLaterLeft.push(event);
      } else {
        onLaterRight.push(event);
      }
    });

    setOnNowLeft(onNowLeft);
    setOnNowRight(onNowRight);
    setOnLaterLeft(onLaterLeft);
    setOnLaterRight(onLaterRight);
  };

  return (
    <div
      className={`dashboard ${selectedTab === "map" ? "hide" : "show"} ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div
        className={`dashboardColumn ${isDarkMode ? "dark-mode" : ""}`}
        ref={column1Ref}
      >
        <h2>Duty Crews</h2>
        <div className="cardList">
          {column1Data.map((item, index) => (
            <div className="dutycard" key={index}>
              Name: {item.name}
              <br />
              Position: {item.position}
              <br />
              On Duty Till: {item.onDutyTillstring}
            </div>
          ))}
          <div style={{ width: "100%", border: "solid 1px black" }}></div>
          {showW2W ? (
            <>
              <h2>W2W:</h2>
              <h2>On Today</h2>
              {lastUpdated && <p>Last Updated: {lastUpdated.toLocaleString()}</p>}
              <div className="columnList">
                <div className="cardList">
                  {onNowLeft.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <span className="cardName">
                        <AccountCircleIcon className="Icon" />
                        {item.FIRST_NAME} {item.LAST_NAME}
                      </span>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br />
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
                <div className="cardList">
                  {onNowRight.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <div className="cardName">
                        <AccountCircleIcon className="Icon" />
                        {item.FIRST_NAME} {item.LAST_NAME}
                      </div>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br />
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: "100%", border: "solid 1px black" }}></div>
              <h2>On Tomorrow</h2>
              {lastUpdated && <p>Last Updated: {lastUpdated.toLocaleString()}</p>}
              <div className="columnList">
                <div className="cardList">
                  {onLaterLeft.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <span className="cardName">
                        <AccountCircleIcon className="Icon" />
                        <span className="NameText"> {item.FIRST_NAME}</span>{" "}
                        <span className="NameText">{item.LAST_NAME}</span>
                      </span>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br />
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
                <div className="cardList">
                  {onLaterRight.map((item, index) => (
                    <div className="matching-card" key={index}>
                      <div className="cardName">
                        <AccountCircleIcon className="Icon" />
                        <span className="NameText"> {item.FIRST_NAME}</span>{" "}
                        <span className="NameText">{item.LAST_NAME}</span>
                      </div>
                      {item.POSITION_NAME} {item.CATEGORY_NAME}
                      <br />
                      Shift: {item.START_DATE} {item.START_TIME} -{" "}
                      {item.END_DATE} {item.END_TIME}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="dashboardColumn" ref={column2Ref}>
        {currentView === "memos" ? (
          <>
            <h2>Memos</h2>
            <div className="cardList">
              {column2Data.map((item, index) => (
                <div className="card" key={index}>
                  <div className="cardName">
                    {item.userImg ? (
                      <img
                        className="profilePhoto"
                        src={item.userImg}
                        alt="Profile"
                      />
                    ) : (
                      <img
                        className="profilePhoto"
                        src="https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230"
                        alt="Default Profile"
                      />
                    )}
                    <span className="NameTextmemo">{item.userName}</span>
                  </div>
                  {item.post}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
          <h2>Unit Status</h2>
            <div className="cardList">
              {units
                .filter(unit => /\d/.test(unit.name)) 
                .map((unit, index) => (
                  <div className="unitCard" key={index}>
                    <div className="unitName">{unit.name}</div>
                    <div
                      className={`unitStatus ${
                        unit.disabledService ? "outOfService" : "inService"
                      }`}
                    >
                      {unit.disabledService ? "Out of Service" : "In Service"}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
